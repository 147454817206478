const config = {
	Backend: {
		ApiEndpoint: '/api'
	},
	MapboxToken: 'pk.eyJ1IjoidmluYXluYiIsImEiOiJja3BjbzdoZ3IxOWpkMnFxbTRiYzRpeWQ5In0.Grqehr8Yd7vDjhusMj7EFA',
	MapTilesets: {
		AustraliaSuburbs: { Id: 'vinaynb.ckkjfbqr711kh21qq29og94xv-851dx', SourceLayer: 'suburb-sa1' },
		AustraliaSa1: { Id: 'vinaynb.0evtgjck', SourceLayer: 'aus-sa1-5pd9e6' },
		AustraliaCda: { Id: 'vinaynb.stgz_aus_cda', SourceLayer: 'stgz_aus_cda' },
		AustraliaCdar: { Id: 'vinaynb.stgz_aus_cdar', SourceLayer: 'stgz_aus_cdar' },
		AustraliaState: { Id: 'vinaynb.a4vb8rqa', SourceLayer: 'aus_state' }
	}
};
export default config;
